<template>
  <div class="modal is-medium is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Agregar $ (Recompensa) a la cuenta bancaria</p>
        <button class="delete" aria-label="close" type="reset" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">Monto</label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="amount"
              placeholder="1,000"
              name="amount"
              :class="{ 'is-danger': vverrors.first('amount') }"
              v-validate="'required'"
              @keypress="onlyNumbers"            >
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div class="field is-grouped">
          <div class="control">
            <button class="button is-link is-light" :disabled="loading" @click="close()">Cancelar</button>
          </div>
          <div class="control">
            <button class="button button-givu" :class="{ 'is-loading': loading }" :disabled="loading" @click="addGivsLocal()">Guardar</button>
          </div>
        </div>
    </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalAddGivs',
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    addGivs: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      amount: null,
      loading: false
    }
  },
  methods: {
    async addGivsLocal () {
      if (await this.$validator.validateAll() && this.amount > 0) {
        this.loading = true
        await this.addGivs(this.amount)
      }
    },
    onlyNumbers (evt) {
      evt = (!evt) ? window.event : evt
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>
